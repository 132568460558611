import { deviceType } from "./utils";

export type GtmEvent =
  | "datalayer-loaded"
  | "application-form-success"
  | "newsletter-success"
  | "account-creation-success-applicant"
  | "account-creation-success-recruiter"
  | "add-to-fav-success"
  | "share-offer";

export type GtmProps = {
  event: GtmEvent;
  pageType?: string;
  page_cat?: string;
  application_job?: string;
  application_ref?: string;
  social?: string;
};

export const gtmTrigger = ({
  event,
  pageType,
  page_cat,
  application_job,
  application_ref,
}: GtmProps) => {
  const envChannel = deviceType();

  type TWindow = typeof window & {
    dataLayer: {
      push: (obj: {
        event: GtmEvent;
        eventProps: {
          pageType?: string;
          envChannel?: string;
          page_cat?: string;
          application_job?: string;
          application_ref?: string;
        };
      }) => void;
    };
  };

  const newWindow = window as TWindow;
  if (newWindow.dataLayer) {
    newWindow.dataLayer.push({
      event,
      eventProps: {
        pageType,
        envChannel,
        page_cat,
        application_job,
        application_ref,
      },
    });
  }
};

import gql from "../../apollo.setup";

export const SIGNUP = gql`
  mutation Signup($CreateUserInput: CreateUserInput!) {
    signup(CreateUserInput: $CreateUserInput) {
      id
      email
      company {
        id
      }
    }
  }
`;

export const LOGIN = gql`
  mutation Login($loginInput: LoginInput!) {
    login(LoginInput: $loginInput) {
      AuthenticationResult {
        AccessToken
        RefreshToken
        IdToken
      }
    }
  }
`;

export const CONFIRM_SIGNUP = gql`
  mutation confirmSignup($ConfirmSignupUserInput: ConfirmSignupUserInput!) {
    confirmSignup(ConfirmSignupUserInput: $ConfirmSignupUserInput) {
      message
    }
  }
`;

export const RESEND_CONFIRMATION_CODE = gql`
  mutation ResendConfirmationCode(
    $ResendConfirmationCodeInput: ResendConfirmationCodeInput!
  ) {
    resendConfirmationCode(
      ResendConfirmationCodeInput: $ResendConfirmationCodeInput
    ) {
      message
    }
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($UpdateEmailUserInput: UpdateEmailUserInput!) {
    updateUserEmail(UpdateEmailUserInput: $UpdateEmailUserInput) {
      id
      email
    }
  }
`;

export const GET_USER_VERIFICATION_CODE = gql`
  mutation GetUserVerificationCode(
    $GetUserVerificationCodeInput: GetUserVerificationCodeInput!
  ) {
    getUserVerificationCode(
      GetUserVerificationCodeInput: $GetUserVerificationCodeInput
    ) {
      success
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyUserEmail($VerifyEmailUserInput: VerifyEmailUserInput!) {
    verifyUserEmail(VerifyEmailUserInput: $VerifyEmailUserInput) {
      success
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($ChangePasswordInput: ChangePasswordInput!) {
    changePassword(ChangePasswordInput: $ChangePasswordInput) {
      success
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($DeleteUserInput: DeleteUserInput!) {
    deleteAccount(DeleteUserInput: $DeleteUserInput) {
      success
    }
  }
`;

export const FORGET_PASSWORD = gql`
  mutation ForgetPassword($ForgetPasswordInput: ForgetPasswordInput!) {
    forgetPassword(ForgetPasswordInput: $ForgetPasswordInput) {
      success
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($ResetPasswordInput: ResetPasswordInput!) {
    resetPassword(ResetPasswordInput: $ResetPasswordInput) {
      success
    }
  }
`;

import gql from "../../apollo.setup";

export const GET_COMPANIES = gql`
  query GetCompanies($GetCompaniesInput: GetCompaniesInput!) {
    getCompanies(GetCompaniesInput: $GetCompaniesInput) {
      data {
        id
        slug
        name
        logoPath
        coverImagePath
        sectors {
          id
          name
        }
      }
      currentPage
      total
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation CreateCompany($UpdateCompanyInput: UpdateCompanyInput!) {
    updateCompany(UpdateCompanyInput: $UpdateCompanyInput) {
      id
      slug
      name
      logoPath
      coverImagePath
    }
  }
`;

export const JOIN_GROUPMENT = gql`
  mutation JoinGroupment($JoinGroupmentInput: JoinGroupmentInput!) {
    joinGroupment(JoinGroupmentInput: $JoinGroupmentInput) {
      id
      name
      picture
      promoCode
      companyCode
      percentage
    }
  }
`;

export const LEAVE_GROUPMENT = gql`
  mutation {
    leaveGroupment
  }
`;

export const GET_COMPANY = gql`
  query GetCompany($GetCompanyInput: GetCompanyInput!) {
    getCompany(GetCompanyInput: $GetCompanyInput) {
      id
      slug
      name
      logoPath
      website
      facebook
      twitter
      youtube
      instagram
      values
      offices {
        city
        country
        longitude
        latitude
        label
      }
      linkedin
      coverImagePath

      sectors {
        id
        name
      }
      pictures {
        id
        path
      }
      keyFigures {
        id
        key
        value
      }
      presentation

      grouping {
        id
        name
        picture
        promoCode
        companyCode
        percentage
      }

      mainGrouping {
        id
        name
        picture
        promoCode
        companyCode
        percentage
      }
    }
  }
`;

export const GET_COMPANY_OFFERS = gql`
  query GetCompanyOffers($GetCompanyOffersInput: GetCompanyOffersInput!) {
    getCompanyOffers(GetCompanyOffersInput: $GetCompanyOffersInput) {
      data {
        id
        slug
        publishedAt
        createdAt
        title
        status
        numberOfNewApplications
        localization {
          id
          country
          city
        }
        contractTypes
        minSalary
        maxSalary
        salaryRate
        numberOfViews
        numberOfCandidatures
        numberOfNonConsultees
      }
      cursor
      total
      fetchMore
    }
  }
`;

export const GET_COMPANY_OFFERS_SLUG = gql`
  query GetCompanyOffersSlug($CursorInputSlug: CursorInputSlug!) {
    getCompanyOffersSlug(CursorInputSlug: $CursorInputSlug) {
      data {
        id
        slug
        publishedAt
        title
        status
        localization {
          id
          country
          city
        }
        contractTypes
        minSalary
        maxSalary
        numberOfViews
        numberOfCandidatures
        numberOfNonConsultees
      }
      cursor
      total
      fetchMore
    }
  }
`;

export const GET_COMPANY_OFFERS_COUNT = gql`
  query {
    getOffersCount {
      value
      count
    }
  }
`;

export const GET_COMPANY_SLUG = gql`
  query GetCompanySlug($GetCompanySlugInput: GetCompanySlugInput!) {
    getCompanySlug(GetCompanySlugInput: $GetCompanySlugInput)
  }
`;

export const GET_FAVORITES_COMPANIES = gql`
  query GetFavoriteCompanies($CursorInput: CursorInput!) {
    getFavoriteCompanies(CursorInput: $CursorInput) {
      data {
        id
        slug
        name
        logoPath
        coverImagePath
        sectors {
          id
          name
        }
      }
      cursor
      total
      fetchMore
    }
  }
`;

export const ADD_COMPANY_TO_FAVORITES = gql`
  mutation AddCompanyToFavorites($companyId: Int!) {
    addCompanyToFavorites(CompanyFavoriteInput: $companyId) {
      success
    }
  }
`;

export const REMOVE_COMPANY_FROM_FAVORITES = gql`
  mutation RemoveCompanyFromFavorites($companyId: Int!) {
    removeCompanyFromFavorites(CompanyFavoriteInput: $companyId) {
      success
    }
  }
`;

export const GET_IS_FAVORITE_COMPANY = gql`
  query GetCompany($GetCompanyInput: GetCompanyInput!) {
    getCompany(GetCompanyInput: $GetCompanyInput) {
      isFavorite
    }
  }
`;

export const ADD_EMAIL_TEMPLATE = gql`
  mutation AddEmailTemplate($AddEmailTemplateInput: AddEmailTemplateInput!) {
    addEmailTemplate(AddEmailTemplateInput: $AddEmailTemplateInput) {
      id
    }
  }
`;

export const GET_ALL_COMPANY_SLUGS = gql`
  query {
    getAllCompanies {
      slug
      createdAt
      offersCount
    }
  }
`;

export const PUBLISH_COMPANY = gql`
  mutation {
    publishCompany
  }
`;

export const SEND_CANDIDATE_EMAIL = gql`
  mutation SendCandidateEmail(
    $SendCandidateEmailInput: SendCandidateEmailInput!
  ) {
    sendCandidateEmail(SendCandidateEmailInput: $SendCandidateEmailInput) {
      success
    }
  }
`;

export const ADD_USER = gql`
  mutation AddUserToCompany($AddUserToCompanyInput: AddUserToCompanyInput!) {
    addUserToCompany(AddUserToCompanyInput: $AddUserToCompanyInput)
  }
`;

export const GET_COMPANY_USERS = gql`
  query {
    getCompanyUsers {
      id
      email
      isOriginal
    }
  }
`;

export const DELETE_COMPANY_USER = gql`
  mutation DeleteCompanyUser($GetUniqueInput: GetUniqueInput!) {
    deleteCompanyUser(GetUniqueInput: $GetUniqueInput)
  }
`;

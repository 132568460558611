import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import styles from "./Input.module.scss";

const SimpleInput: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & { suffix?: string }
> = ({ ...props }) => {
  const [visible, setVisible] = useState(false);

  const onEyeClick = () => {
    setVisible((prev) => !prev);
  };
  return (
    <div className={styles.input_container}>
      <label htmlFor={props.id}>{props.placeholder}</label>
      {props.type !== "password" ? (
        <div className={styles.input_content}>
          <input id={props.id} {...props} placeholder="" autoComplete="off" />
          <p className={styles.input_suffix}>{props.suffix}</p>
        </div>
      ) : (
        <div className={styles.pass}>
          <input
            id={props.id}
            {...props}
            placeholder=""
            autoComplete="off"
            type={visible ? "text" : "password"}
          />
          {visible ? (
            <AiFillEye onClick={onEyeClick} className={styles.icon} />
          ) : (
            <AiFillEyeInvisible onClick={onEyeClick} className={styles.icon} />
          )}
        </div>
      )}
    </div>
  );
};

export default SimpleInput;

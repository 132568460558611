import { useRouter } from "next/router";
import React from "react";
import styled from "styled-components";

import { RoleEnum } from "../../../types/enum.types";
import { HOME_PAGE } from "../../../utils/pages";
import Logo from "../../assets/icons/Logo";
import styles from "./CenteredBox.module.scss";

const Button = styled.button`
  padding: 10px 30px;
  border: 3px solid var(--color-dark-1);
  color: var(--color-dark-1);
  background-color: transparent;
  margin-top: 5px;
  border-radius: 7px;
  font-weight: bold;
  cursor: pointer;
`;

const CenteredBoxPosition = styled.div<{
  width?: string;
  questionColor?: string;
  surrounded?: boolean;
}>`
  width: ${(props) => props.width || "25%"};
  margin-left: auto;
  margin-right: auto;

  h4 {
    color: ${(props) =>
      props.questionColor || "var(--color-dark-1)"} !important;
    cursor: pointer;
  }

  form {
    padding: ${(props) => (props.surrounded ? "8%" : 0)};
    border-radius: 7px;
  }

  @media screen and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 800px) {
    width: 95%;
  }
`;

interface Props {
  id?: string;
  children: React.ReactNode;
  question?: string;
  questionColor?: string;
  description?: string;
  button_label?: string;
  width?: string;
  showLogo?: boolean;
  surrounded?: boolean;
  role?: RoleEnum;
  autocomplete?: string;
  onSubmit: () => void;
  onActionClick?: () => void;
}

const CenteredBox: React.FC<Props> = ({
  children,
  question,
  questionColor,
  description,
  button_label,
  width,
  showLogo = true,
  surrounded = true,
  role,
  autocomplete,
  onSubmit,
  onActionClick,
  id,
}) => {
  const router = useRouter();
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <CenteredBoxPosition
      surrounded={surrounded}
      width={width}
      questionColor={questionColor}
    >
      <form
        id={id ?? "centered-box-form"}
        autoComplete={autocomplete}
        className={styles.container}
        onSubmit={handleSubmit}
      >
        {showLogo && (
          <div className={styles.header}>
            <div
              onClick={() => {
                router.push(HOME_PAGE);
              }}
            >
              <Logo entreprise={role === RoleEnum.COMPANY} />
            </div>
          </div>
        )}
        {children}
      </form>

      <div className={`${(question || description) && styles.footer}`}>
        {question && <p onClick={onActionClick}>{question}</p>}
        {description && <p>{description}</p>}
        {button_label && (
          <Button onClick={onActionClick}>{button_label}</Button>
        )}
      </div>
    </CenteredBoxPosition>
  );
};

export default CenteredBox;

import React from "react";
import styled from "styled-components";

interface Props {
  label: string;
  width: string;
  type?: "button" | "submit";
  disabled?: boolean;
}

const Button = styled.button<{ width: string; disabled?: boolean }>`
  width: ${(props) => props.width};
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 4%;
  border: none;
  cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};
  border-radius: 7px;
  background-color: ${(props) =>
    props.disabled ? "var(--color-tags)" : "var(--color-primary)"};
  color: white;
  font-weight: bold;
  font-family: var(--font-title-regular);
`;

const SimpleButton: React.FC<Props> = ({
  label,
  width,
  type = "button",
  disabled = false,
}) => {
  return (
    <Button disabled={disabled} type={type} width={width}>
      {label}
    </Button>
  );
};

export default SimpleButton;

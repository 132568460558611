import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { SIGNUP } from "../../../api/graphql/auth";
import { UPDATE_COMPANY } from "../../../api/graphql/company";
import { TOGGLE_NEWSLETTER } from "../../../api/graphql/user";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { SignupRequest, SignupResponse } from "../../../types/auth.type";
import {
  Company,
  UpdateCompanyRequest,
  UpdateCompanyResponse,
} from "../../../types/company.type";
import { Breakpoint, RoleEnum } from "../../../types/enum.types";
import { ErrorState } from "../../../types/error.types";
import {
  SubscribeToNewsLetterRequest,
  UserData,
} from "../../../types/user.types";
import { gtmTrigger } from "../../../utils/gtm";
import { cipher } from "../../../utils/hash";
import { LOGIN_PAGE, VERIFY_EMAIL_PAGE } from "../../../utils/pages";
import { passwordErrors } from "../../../utils/utils";
import { ParagraphError } from "../../errors/ParagraphError";
import CheckedInput from "../../Input/CheckedInput";
import SimpleInput from "../../Input/SimpleInput";
import SimpleButton from "../../SimpleButton/SimpleButton";
import CenteredBox from "../CenteredBox";

const UL = styled.ul`
  margin: 0;
  padding: 10px;
`;

interface Props {
  action?: () => void;
  surrounded?: boolean;
  role: RoleEnum;
}
const Inscription: React.FC<Props> = ({ action, surrounded, role }) => {
  const [user, setUser] = useState<UserData>({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [company, setCompany] = useState<Company>({});
  const [passwordError, setPasswordError] = useState<ErrorState[] | null>(null);
  const [checked, setChecked] = useState(false);
  const [token, setToken] = useState<string>();

  const router = useRouter();
  const breakpoint = useBreakpoint();
  const [signupTrigger, { error: signupError, loading: signupLoading }] =
    useMutation<SignupResponse, SignupRequest>(SIGNUP);

  const [newsletterTrigger] = useMutation<never, SubscribeToNewsLetterRequest>(
    TOGGLE_NEWSLETTER
  );
  const [updateCompanyTrigger] = useMutation<
    UpdateCompanyResponse,
    UpdateCompanyRequest
  >(UPDATE_COMPANY);

  const isMedium =
    breakpoint.includes(Breakpoint.XS) && !breakpoint.includes(Breakpoint.LG);

  const handleSubmit = async () => {
    if (
      passwordError &&
      passwordError.length > 0 &&
      passwordError.find((error) => !error.validated)
    )
      return false;

    setPasswordError(null);
    const result = await signupTrigger({
      variables: {
        CreateUserInput: {
          email: user.email,
          password: user.password,
          userRole: role,
          token,
        },
      },
    });

    if (role === RoleEnum.APPLICANT) {
      gtmTrigger({
        event: "account-creation-success-applicant",
      });
    }
    if (role === RoleEnum.COMPANY) {
      gtmTrigger({
        event: "account-creation-success-recruiter",
      });
    }
    if (!result.errors) {
      const hashedPassword = cipher(user.password);
      localStorage.setItem("email", user.email);
      localStorage.setItem("password", hashedPassword);
      if (isMedium && action) action();
      else if (result.data?.signup.company?.id)
        router.push(
          `${VERIFY_EMAIL_PAGE}?companyId=${result.data?.signup.company?.id}`
        );
      else router.push(VERIFY_EMAIL_PAGE);
    }
    if (checked && result.data?.signup.id) {
      gtmTrigger({
        event: "newsletter-success",
      });
      await newsletterTrigger({
        variables: {
          SubscribedToNewsLetterInput: {
            id: result.data?.signup.id,
            subscribedToNewsletter: checked,
          },
        },
      });
    }
    if (result.data?.signup.company?.id)
      await updateCompanyTrigger({
        variables: {
          UpdateCompanyInput: {
            id: result.data?.signup.company?.id,
            name: company.name,
            phoneNumber: company.phoneNumber,
          },
        },
      });
  };

  const handleInput = (event: React.ChangeEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    if (name) {
      setUser((prev) => ({
        ...prev,
        [name]: value ?? "",
      }));
    }
  };

  useEffect(() => {
    setPasswordError(passwordErrors(user.password, user.confirmPassword));
  }, [user.password, user.confirmPassword]);

  useEffect(() => {
    const companyName = router.query.companyName;
    const email = router.query.email;
    const token = router.query.token;

    if (
      companyName &&
      typeof companyName === "string" &&
      email &&
      typeof email === "string" &&
      token &&
      typeof token === "string"
    ) {
      setUser((prev) => ({
        ...prev,
        email,
      }));
      setCompany((prev) => ({
        ...prev,
        name: companyName,
      }));

      setToken(token);
    }
  }, [router.query]);

  return (
    <div className={role === RoleEnum.COMPANY ? "company-variables" : ""}>
      <CenteredBox
        role={role}
        question="Déjà un compte ?"
        questionColor="var(--color-primary)"
        onSubmit={() => {
          handleSubmit();
        }}
        onActionClick={() => {
          router.push(LOGIN_PAGE);
        }}
        surrounded={surrounded}
        width={"23%"}
        id={
          role === RoleEnum.COMPANY
            ? "inscription-entreprise"
            : "inscription-candidat"
        }
      >
        {role === RoleEnum.COMPANY && (
          <>
            <SimpleInput
              id="enterprise-name"
              required
              placeholder="Nom de l'entreprise"
              type="text"
              name="name"
              onChange={(e) => setCompany({ ...company, name: e.target.value })}
              value={company.name}
            />
            <SimpleInput
              id="phoneNumber"
              required
              placeholder="Numéro de téléphone"
              type="text"
              name="phoneNumber"
              onChange={(e) =>
                setCompany({ ...company, phoneNumber: e.target.value })
              }
              value={company.phoneNumber}
            />
          </>
        )}

        <SimpleInput
          id="email"
          required
          placeholder="E-mail"
          type="email"
          name="email"
          onChange={handleInput}
          value={user.email}
        />
        <SimpleInput
          id="password"
          required
          placeholder="Mot de passe"
          type="password"
          name="password"
          onChange={handleInput}
          value={user.password}
        />
        <SimpleInput
          id="confirm-password"
          required
          placeholder="Confirmation du mot de passe"
          type="password"
          name="confirmPassword"
          onChange={handleInput}
          value={user.confirmPassword}
        />
        <small>
          <b>
            {passwordError && passwordError.length > 0 ? (
              <UL>
                {passwordError.map((error, index) => (
                  <li key={index}>
                    <ParagraphError isError={!error.validated}>
                      {error.message}
                    </ParagraphError>
                  </li>
                ))}
              </UL>
            ) : (
              signupError && (
                <ParagraphError center isError={true}>
                  {signupError.message ===
                  "An account with the given email already exists."
                    ? "Un compte avec l’adresse e-mail donnée existe déjà."
                    : signupError.message}
                </ParagraphError>
              )
            )}
          </b>
        </small>
        <CheckedInput
          label="Je souhaite recevoir la newsletter de Swala.fr"
          checked={checked}
          onClick={() => {
            setChecked(!checked);
          }}
        />
        <SimpleButton
          disabled={signupLoading}
          type="submit"
          label="Inscription"
          width={"100%"}
        />
      </CenteredBox>
    </div>
  );
};

export default Inscription;

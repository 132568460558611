import React from "react";
import styled from "styled-components";
interface Props {
  label?: string;
  checked?: boolean;
  onClick?: () => void;
  isError?: boolean;
}

const RadioButton = styled.div<{
  isError?: boolean;
}>`
  width: 20px;
  height: 20px;
  border: ${({ isError }) =>
    isError ? "3px solid var(--color-red)" : "3px solid var(--color-border)"};

  background-color: ${({ isError }) =>
    isError ? "var(--color-red-light)" : "var(--color-fields)"};
  border-radius: 6px;
  position: relative;
`;

const RadioValue = styled.div`
  width: 10px;
  height: 10px;
  background-color: var(--color-primary);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
`;

const Flex = styled.div`
  display: flex;

  label {
    margin-left: 10px;
  }
`;

const Label = styled.label<{ checked: boolean }>`
  font-weight: bolder;
  font-family: ${(props) =>
    props.checked ? "var(--font-title-bold)" : "var(--font-title-light)"};
`;

const CheckedInput: React.FC<
  Props &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
> = ({ label, isError = false, checked = false, onClick, ...props }) => {
  return (
    <Flex>
      <RadioButton id={props.id} onClick={onClick} isError={isError}>
        {checked && <RadioValue></RadioValue>}
      </RadioButton>
      <Label htmlFor={props.id} checked={checked}>
        {label}
      </Label>
    </Flex>
  );
};

export default React.memo(CheckedInput);

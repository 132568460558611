import styled from "styled-components";

export const ParagraphError = styled.p<{
  center?: boolean;
  isError?: boolean;
  margintTop?: string;
}>`
  color: ${(props) => (props.isError ? "red" : "var(--color-dark-1)")};
  font-size: 80%;
  margin-top: ${(props) => props.margintTop || "0"}};
  margin-bottom: 5px;
  margin-left: 5px;
  text-align: ${(props) => (props.center ? "center" : "left")};
`;
